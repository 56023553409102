.dropdown {
	position: relative;
	margin-bottom: 10px;

	.trigger {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 138px;
		color: #fff;
		padding: 10px 0;
		font-size: 0.9em;
		cursor: pointer;

		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: #76879a;
			transform: scaleY(0.5);
		}

		.arrow {
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid #fff;
			margin: 5px;
		}
	}

	.list {
		position: absolute;
		top: -20px;
		right: 0;
		height: 256px;
		padding: 0;
		overflow-y: auto;
		border-radius: 4px;
		background-color: #1d2733;
		box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.2);

		&.rtl {
			left: 0;
			right: auto;
		}

		li {
			margin: 0;
			padding: 13px;
			list-style-type: none;
			color: #fff;
			font-size: 0.9em;
			cursor: pointer;

			&:hover, &.selected {
				background-color: #565d66;
			}

			.short {
				display: none;
			}
		}
	}
}

@media (max-width: 768px) {
	.dropdown {
		position: relative;
		margin-bottom: 4px;
	
		.trigger {
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 50px;
			color: #fff;
			padding: 4px 0 5px;
			font-size: 0.6em;
			cursor: pointer;
	
			&::after {
				content: "";
				position: absolute;
				width: 100%;
				height: 1px;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: #76879a;
				transform: scaleY(0.5);
			}
	
			.arrow {
				border: solid #ededed;
				border-width: 0 1px 1px 0;
				display: inline-block;
				padding: 3px;
				margin-top: 1px;
				transform: rotate(45deg);
  				-webkit-transform: rotate(45deg);
			}
		}
	}
}




.header {
    position: fixed;
	width: 100%;
	height: 100px;
	padding: 0 38px;
	background: #3f4c5c;
	z-index: 50;

    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;

    nav {
        width: 200px;
        margin: 0;

        a {
            display: block;
            margin: 0;

            svg {
                display: block;
                width: 200px;
                height: 36px;
            }
        }
    }

    :global .filler {
        flex: 1 1 auto;
    }
}

@media (max-width: 768px) {
    .header {
        height: 65px;
	    padding: 0 20px;
        background: #1d2733;
        border-bottom: 1px solid #3f4c5c;

        nav {
            width: 112px;
            margin: 0;
    
            a {
                display: block;
                margin: 0;
    
                svg {
                    display: block;
                    width: 112px;
                    height: 20px;
                }
            }
        }
    }
}

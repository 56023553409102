.shareTrigger {
  cursor: pointer;
  margin: 15px 60px 11px;

  &.highlight {
    border-radius: 50%;
    background-color: #65707d;
    margin: 0 57px;
    padding: 3px 3px 0;
  }

  svg {
	  width: 40px;
	  height: 40px;
  }
}

.shareButtons {
  position: relative;
  height: 56px;

  ul {
	list-style: none;
	height: 56px;
	position: absolute;
	display: block;
	overflow: hidden;
	right: 110px;
	margin: 0;
	padding: 12px 0;
	z-index: 99;
	border-radius: 26px;
	background: #3f4c5c;
	opacity: 0;
	transition-property: opacity;
	transition-duration: 0.5s;
  
	&.rtl {
	  right: auto;
	  left: 110px;
	}
  
	&.open {
	  opacity: 1;
	}
  
	li {
	  display: inline;
	  margin: 0 7px;
  
	  img {
		width: 30px;
		height: 30px;
	  }
	}
  }
}

.snackbar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  background-color: #3f4c5c;
  color: #fff;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .shareTrigger {
	margin: 0 11px;

	&.highlight {
	  border-radius: 0;
	  background: none;
	  margin: 0 11px;
	  padding: 0;
	}

	svg {
  	  width: 25px;
	  height: 25px;
	}

	&.ios {
		svg {
			height: 17px;
		}
	}
  }

  .shareButtons {
	position: static;
	height: 56px;
	  
	ul {
	  list-style: none;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  width: 100%;
	  height: 0;
	  top: 65px;
	  right: 0;
	  left: 0;
	  margin: 0;
	  padding: 0;
	  opacity: 0;
	  box-sizing: border-box;
	  border-radius: 0;
	  background-color: rgba($color: #303d4c, $alpha: 0.98);
	  transition-property: height, opacity;
	  transition-duration: 0.2s;

	  &.rtl {
		right: 0;
		left: 0;
	  }
	
	  &.open {
		height: 85px;
		opacity: 1;
	  }

	  li {
		display: inline;
		padding: 29px 7px;
		margin: 0;

		a {
		  display: block;

		  img {
			display: block;
		  }
		}
	  }
	}
  }
}
.banner {
    flex-shrink: 0;
    width: 100%;
    max-height: 500px;
    overflow: hidden;
    transition: max-height 1s;
    z-index: 49 !important;
    position: absolute !important;
    top: 100px !important;
  
    &.bottom {
      position: relative;
      top: auto !important;
      bottom: 61px;
  
      :global(.gdpr-banner) {
        background-color: rgba(29, 39, 51, 0.98);
        color: #76879A !important;
        padding: 16px 15px 24px !important;
        font-size: 13px !important;
        line-height: 16px !important;
  
        &::before {
          content: '';
          position: absolute;
          display: block;
          height: 1px;
          top: 0;
          right: 0;
          left: 0;
          transform: scaleY(0.5);
          background: #76879A;
        }
  
        a {
          color: #76879A !important;
        }
      }

      :global(.align-items-center) {
        width: 100%;
        flex-direction: column !important;
      }
  
      :global(.banner-text) {
        width: auto !important;
        text-align: left;
        align-items: flex-start;
        font-size: 13px !important;
        line-height: 16px !important;
        margin-bottom: 16px;
      }
  
      :global(.banner-button) {
        width: 100% !important;
        margin: 0;
    
        a {
          width: 100% !important;
          height: 46px !important;
          font-size: 15px !important;
          line-height: 46px !important;
          border-radius: 23px !important;
          margin: 0 !important;
          padding: 0 !important;
          box-sizing: border-box;
          color: #7667D5 !important;
          border-color: #7667D5 !important;
        }
      }
  
      &[dir=rtl] {
        .banner-text {
          text-align: right;
          align-items: flex-end;
        }
      }
    }

    :global(.gdpr-close) {
        width: 16px;
        height: 16px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAQAAAC0NkA6AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAHdElNRQflCgMFCBF2+v/PAAACbElEQVRYw+3W20tUURjG4beRpCAqywMdTCI1TxhBlFlZXkgUUXgfERFRdC1B/RcRRURRWtBFRBGGN5aV2REhS02NCishOhmUEMx8vy5GbWY5M+4941Xs93LWt76HvWfttZYUJEiQIJIQORSgSMoqi9bNwT9wXIhcmmljJbKkdREhimnlBNk+mbAQebRgQBtFyRgTooQ7wBjHfDEmRD5XMKK5zYpEjAlRSsd41W+amO2ZQeRzdZIAaKUQMZVYzb2Yql80eX4aRDVDxOcWy2OXgAlRzgOnqptCj4gJ0cA7p8FNlqHwv4oKOp2KQepTLZJEzHbeO01usBSFo6OVdDmjA2x1X6kXZscU5jpLEKKKR85IP3U+niKO2cmw0+waBVTxxPm1j81pEJPMLj7EtTPaeOYQvdSmSUwyu/lIqrxiYwaEJCHEHj4lJXqoyZCIMmWikZGExAvWzwARZf6IRj4nINZ5I0JekGxpRGNTBoZ566nB9DEhNtCT4GVFuMjiNM6RhEQNL5P87REusChDxoSopTfFAo5wnpwMGBNiE31O2yG+OMw5FqbJmBBb6HeIx1Szl68OczYtxoSo47VDdFGJbBb7HCbMGRb4ZBBiGwMO8ZAKZEIWYj/fHOY08/2e8fUMOkQn5ROfHrIQB/juMKc8M0S/izcOcZ+y2K8bWYiD/HCYk8z1xBA9Wp/GTe+g1N1AkIU45DAtzPNzxlfHMHcpSXwlsiwOMzpeZVwmb7r7psus4TkA7RQnm2qyLI7wE4jQTO7ERcMPs5Zu2lmVaqrJsjjKKJfS2sXGL29F023miGwayJ+BjTJIkCD/Rf4CE7MMbcqrAEEAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMTAtMDNUMDU6MDg6MTctMDQ6MDAJ1TQ7AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTEwLTAzVDA1OjA4OjE3LTA0OjAweIiMhwAAAABJRU5ErkJggg==');
        background-size: contain;
        img {
            opacity: 0.001;
        }
    }
  
    &.removing {
      max-height: 0;
    }
  
    :global(.banner-container) {
      max-width: none !important;
    }
  
    :global(.align-items-center) {
      justify-content: center;
    }
  
    :global(.banner-text) {
      width: auto !important;
    }
  
    :global(.banner-button) {
      width: auto !important;
      margin: 0 50px;
  
      a {
        width: auto !important;
        padding: 0 65px !important;
        white-space: nowrap;
      }
    }
  }